import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {/* <span className="purple">THIS IS FIRE : </span> */}
            <br />
            Pepe is tired of watching everyone play hot potato with the endless derivative ShibaCumGMElonKishuTurboAssFlokiMoon Inu coins. The Inu’s have had their day. It’s time for the most recognizable meme in the world to take his reign as king of the memes.
            <br />
          </p>
          <p style={{ textAlign: "justify" }}>
            {/* <span className="purple">THIS IS FIRE : </span> */}
            <br />
            Pepe is here to make memecoins great again. Launched stealth with no presale, zero taxes, LP burnt and contract renounced, $PEPE is a coin for the people, forever. Fueled by pure memetic power, let $PEPE show you the way.
          </p>
          {/* <ul>
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              ROAD MAP <strong className="purple"> </strong>
            </h1>
            <li className="about-activity">
              <ImPointRight /> 🔥 BUY
            </li>
            <li className="about-activity">
              <ImPointRight /> 🔥 HOLD
            </li>
            <li className="about-activity">
              <ImPointRight /> 🔥 STAY ON FIRE
            </li>
          </ul> */}

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "JOIN THE FUTURE OF AI"{" "}
          </p> */}
          <footer className="blockquote-footer"> PEPE </footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;


