import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import one from "../../Assets/Projects/1.png";
import two from "../../Assets/Projects/2.png";
import three from "../../Assets/Projects/3.png";
import logo from "../../Assets/pepe1.jpg";
import five from "../../Assets/Projects/5.png";


function Projects() {
  return (
    <Container fluid className="project-section" id="project">
      <Particle />
      <Container>
        <h1 className="project-heading">
          $PEPE <strong className="purple">TOKENOMICS</strong>
        </h1>
        {/* <p style={{ color: "white" }}>
          Here are a few Things That we are going to do ...
        </p> */}
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={logo}
              isBlog={false}
              title="TOTAL SUPPLY"
              line1='1,000,000,000'
              line2='FAIR LAUNCH'
              line3='ETH TRENDING'
              line4='TELEGRAM MARKETING'
              line5='TWITTER MARKETING'
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={logo}
              isBlog={false}
              title="TAX"
              line1='1/1'
              line2='CG LISTING'
              line3='CMC LISTING'
              line4='LIST IN ALL CRYPTO SITES'
              line5='MEME COMPUTATIONS'
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={logo}
              isBlog={logo}
              title="Liquidity"
              line1='LOCKED'
              line2='TWITTER SHILLS'
              line3='PARTNERSHIP WITH INFLUENCERS'
              line4='CEX LISTING'
              line5='MANY MORE COMING - TBA'
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
