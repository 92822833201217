export const telegram = "https://t.me/pepe_pepe_eth"
export const twitter = "https://twitter.com/pepe_pepe_eth"
export const poo = "https://www.dextools.io/app/ether/pair-explorer/0x0851c707dc0b02c3a1835aad83a5f452296c4565"
export const pankcake = "https://app.uniswap.org/#/swap?outputCurrency=0x0851c707dc0b02c3a1835aad83a5f452296c4565"


// echo "# pepetok" >> README.md
// git init
// git add README.md
// git commit -m "first commit"
// git branch -M main
// git remote add origin https://github.com/m0nithin/pepetok.git
// git push -u origin main