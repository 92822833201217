import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import pdf from "../../Assets/../Assets/Soumyajit_Behera-BIT_MESRA.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import r1 from "../../Assets/r1.webp";
import r2 from "../../Assets/r2.webp";
import { pankcake } from "../../links";
import { ImPointRight } from "react-icons/im";

function ResumeNew() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="about-section">
        <Particle />
        <Container>
          <Row style={{ justifyContent: "center", padding: "10px", alignItems: "center" }}>
            <Col>
              <img src={r1} alt="about" className="img-fluid homeLogo" aria-labelledby="wave" />

              <button className="playButton">
                <a
                  href={pankcake}
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY $PEPE NOW
                </a>
              </button>
            </Col>
            <Col
              md={7}
              style={{
                justifyContent: "center",
                paddingTop: "30px",
                paddingBottom: "50px",
              }}
            >
              <h1 className="project-heading">
                Our <strong className="purple">Roadmap </strong>
              </h1>
              <p style={{ color: "white" }}>
                Here are a few Things That we are going to do ...
              </p>
              <h1 style={{ fontSize: "1.6em", paddingBottom: "20px" }}>
                <ImPointRight /> Phase 1: Meme <strong className="purple"> </strong>
              </h1>
              <h1 style={{ fontSize: "1.6em", paddingBottom: "20px" }}>
                <ImPointRight />Phase 2: Vibe and HODL <strong className="purple"> </strong>
              </h1>
              <h1 style={{ fontSize: "1.6em", paddingBottom: "20px" }}>
                <ImPointRight /> Phase 3: Meme Takeover <strong className="purple"> </strong>
              </h1>
            </Col>
            <Col>
              <img src={r2} alt="about" className="img-fluid homeLogo" aria-labelledby="wave" />

              <button className="playButton">
                <a
                  href={pankcake}
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY $PEPE NOW
                </a>
              </button>
            </Col>



          </Row>
          {/* <h1 className="project-heading">
            All <strong className="purple"> Listing</strong>
          </h1> */}

          {/* <Techstack /> */}

          {/* <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />

        <Github /> */}
        </Container>
      </Container>
    </div>
  );
}

export default ResumeNew;
